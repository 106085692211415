import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TextH1 from '../../ui/Text/TextH1'
import TextNormal from '../../ui/Text/TextNormal'
import ButtonLG from '../../ui/Buttons/ButtonLG'
import { useTranslation } from 'react-i18next'

const BannerSingle = ({ banner, link }) => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')
  const navigate = useNavigate()

  return !xsOnly ? (
    <Box
      sx={{
        height: '200px',
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'cover',
        backgroundSize: 'cover',
        py: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        px: '30px',
        borderRadius: '4px',
        overflow: 'hidden',
        mb: '30px',
      }}
    >
      <TextH1
        sx={{
          marginRight: '10px',
          lineHeight: 'normal',
          mb: '5px',
          '&.xs-only': {
            mr: 0,
            px: '20px',
            mt: '30px',
            textAlign: 'center',
            mb: '21px',
          },
        }}
      >
        {t('title_banner_helping_getting_greener')}
      </TextH1>
      <TextNormal
        sx={{
          mb: '20px',
          width: { xs: 'auto', md: '70%' },
        }}
      >
        {t('p_banner_helping_getting_greener')}
      </TextNormal>
    </Box>
  ) : null
}

export default BannerSingle
