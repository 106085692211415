import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Grid, Stack } from '@mui/material'
import UITextRegular from '../../../ui/UIText/UITextRegular'
import UITextLabelLarge from '../../../ui/UIText/UITextLabelLarge'
import UINoDataIcon from '../../../ui/UIIcons/UINoDataIcon'
import { getFormattedDate } from '../../../utils/date.helpers'
import CollapseBlock from '../../../ui/Accordions/CollapseBlock'
import { vehicleExpertiseNames } from '../../../utils/constants/enums.constants'
import { sumDelimiter } from '../../../utils/sumDelimiter'
import { colorsConfig } from '../../../config/themeConfig'
import IconFile from '../../../assests/img/icons/icon-document.svg'
import { useTranslation } from 'react-i18next'
import { downloadPrivateFile } from '../../../store/features/files/files.actions'
import UIPreloader from '../../../ui/UIPreloader/UIPreloader'
import { Gallery } from 'react-photoswipe-gallery'
import CarPhotosItem from '../../../components/CarComponents/CarPhotos/CarPhotosItem'
import UITextButton from '../../../ui/UIButtons/UITextButton'
import { DownloadRounded } from '@mui/icons-material'
import { downloadRestitutionFiles } from '../../../store/features/rviPolicy/rviPolicy.actions'

const RVIPolicyRestitutionInfo = () => {
  const { t } = useTranslation()

  const role = useSelector((state) => state.auth.role)
  const policy = useSelector((state) => state.rviPolicy.data)
  const isDownloadingFiles = useSelector(
    (state) => state.rviPolicy.downloadRestitutionFiles.isLoading,
  )
  const restitutionDetails = policy?.restitutionDetails ?? {}
  const {
    restitutionDate,
    location,
    vehicleExpertise,
    mileage,
    thirdPartyLink,
    comment,
    vehicleRegistrationCard,
    appraisalDocument,
    auxiliaryDocuments,
  } = restitutionDetails ?? {}
  const isLoadingSalesPhotos = useSelector((state) => state.rviPolicy.salesPhotos.isLoading)
  const salesPhotos = useSelector((state) => state.rviPolicy.salesPhotos.data)
  const dispatch = useDispatch()

  const [collapsed, setCollapsed] = useState(false)

  return (
    <CollapseBlock
      title="title_restitution_details"
      collapsed={collapsed}
      onCollapseChange={(value) => setCollapsed(value)}
      addons={
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
          <UITextButton
            isLoading={isDownloadingFiles}
            prefixIcon={<DownloadRounded />}
            text="button_download_all_documents"
            preloaderText="preloader_downloading_files"
            onClick={() =>
              dispatch(
                downloadRestitutionFiles({
                  policyId: policy?.id,
                  fileName: policy?.details?.vin ?? policy?.details?.policyNumber,
                }),
              )
            }
          />
        </Box>
      }
    >
      <Stack gap="20px">
        <Stack direction="row" gap="40px" sx={{ mb: '20px', flexWrap: 'wrap' }}>
          <Stack gap="4px">
            <UITextRegular text="form_input_label_restitute_date" />
            <UITextLabelLarge>
              {getFormattedDate(restitutionDate) ?? <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="form_input_label_location_of_car" />
            <UITextLabelLarge>{location ?? <UINoDataIcon isNormalized />}</UITextLabelLarge>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="form_input_label_vehicle_expertise" />
            <UITextLabelLarge>
              {vehicleExpertiseNames?.[vehicleExpertise] ?? <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          <Stack gap="4px">
            <UITextRegular text="form_input_label_mileage" />
            <UITextLabelLarge>
              {mileage ? `${sumDelimiter(mileage)} km` : <UINoDataIcon isNormalized />}
            </UITextLabelLarge>
          </Stack>
          {thirdPartyLink && (
            <Stack gap="4px">
              <UITextRegular text="form_input_label_third_party_link" />
              <UITextRegular
                component="a"
                href={thirdPartyLink}
                target="_blank"
                sx={{ color: colorsConfig.mainBlue }}
              >
                {thirdPartyLink ?? <UINoDataIcon isNormalized />}
              </UITextRegular>
            </Stack>
          )}
          <Stack gap="4px">
            <UITextRegular text="form_input_label_vehicle_registration_card" />
            <Button
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                px: '15px',
                justifyContent: 'flex-start',
                fontFamily: '"Helvetica", sans-serif',
                textTransform: 'none',
                fontWeight: 700,
                fontSize: '16px',
                py: '2px',
                borderRadius: '8px',
                minHeight: 0,
                color: colorsConfig.mainBlue,
                pl: '10px !important',
                position: 'relative',
                ml: '-10px',
              }}
              onClick={() => dispatch(downloadPrivateFile({ fileId: vehicleRegistrationCard }))}
            >
              <img style={{ width: '15px' }} src={IconFile} alt="" />
              {t('button_download')}
            </Button>
          </Stack>
          {appraisalDocument && (
            <Stack gap="4px">
              <UITextRegular text="form_input_label_appraisal_document" />
              <Button
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  px: '15px',
                  fontFamily: '"Helvetica", sans-serif',
                  textTransform: 'none',
                  fontWeight: 700,
                  fontSize: '16px',
                  py: '2px',
                  borderRadius: '8px',
                  minHeight: 0,
                  color: colorsConfig.mainBlue,
                  pl: '10px !important',
                  position: 'relative',
                  ml: '-10px',
                }}
                onClick={() => dispatch(downloadPrivateFile({ fileId: appraisalDocument }))}
              >
                <img style={{ width: '15px' }} src={IconFile} alt="" />
                {t('button_download')}
              </Button>
            </Stack>
          )}
          {auxiliaryDocuments?.length > 0 && (
            <Stack gap="4px">
              <UITextRegular
                text={`${t('form_input_label_additional_documentation')} (${auxiliaryDocuments?.length})`}
              />
              <Button
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  px: '15px',
                  fontFamily: '"Helvetica", sans-serif',
                  textTransform: 'none',
                  fontWeight: 700,
                  fontSize: '16px',
                  py: '2px',
                  borderRadius: '8px',
                  minHeight: 0,
                  color: colorsConfig.mainBlue,
                  pl: '10px !important',
                  position: 'relative',
                  ml: '-10px',
                }}
                onClick={() => {
                  auxiliaryDocuments.forEach((doc) => {
                    dispatch(downloadPrivateFile({ fileId: doc?.document }))
                  })
                }}
              >
                <img style={{ width: '15px' }} src={IconFile} alt="" />
                {t('button_download_all')}
              </Button>
            </Stack>
          )}
          {comment && (
            <Stack gap="4px">
              <UITextLabelLarge text="form_input_label_comment" />
              <UITextRegular>{comment}</UITextRegular>
            </Stack>
          )}
        </Stack>
        {salesPhotos && salesPhotos?.length > 0 && (
          <Grid container spacing="24px">
            <Grid item xs={12}>
              <UITextLabelLarge text="form_input_label_sales_photos" />
            </Grid>
            {
              isLoadingSalesPhotos ? (
                <Box sx={{ position: 'relative', height: '100px' }}>
                  <UIPreloader text="Loading sales photos..." />
                </Box>
              ) : (
                <Box sx={{ mt: '20px' }}>
                  <Gallery>
                    <Grid container spacing={{ xs: 1, md: 2 }}>
                      {salesPhotos.map((item, index) => {
                        return (
                          <Grid item xs={6} md={3} key={index}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '6px',
                                overflow: 'hidden',
                                height: {
                                  xs: 'auto',
                                  md: '160px',
                                },
                              }}
                            >
                              <CarPhotosItem photo={item?.signedUrl} />
                            </Box>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Gallery>
                </Box>
              )

              //   salesPhotos.map((item) => {
              //
              //     return (
              //       <Grid key={item?.id} item xs={6} md={3}>
              //         <Box
              //           sx={{
              //             height: '150px',
              //             overflow: 'hidden',
              //             borderRadius: '6px',
              //             display: 'flex',
              //             justifyContent: 'center',
              //             alignItems: 'center',
              //             '& img': {
              //               width: '100%',
              //               objectFit: 'cover',
              //             },
              //           }}
              //         >
              //           <img src={item?.signedUrl} alt="" />
              //         </Box>
              //       </Grid>
              //     )
              //   })
              // )
            }
          </Grid>
        )}
      </Stack>
    </CollapseBlock>
  )
}

export default RVIPolicyRestitutionInfo
