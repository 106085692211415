import React from 'react'
import { Button, useMediaQuery } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'
import { AddRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const UIButtonAdd = ({ text, children, ...props }) => {
  const { t } = useTranslation()
  const xsOnly = useMediaQuery('(max-width: 600px)')

  return (
    <Button
      fullWidth={xsOnly}
      className={xsOnly ? 'xs-only' : ''}
      variant="contained"
      sx={{
        background: colorsConfig.mainGreen,
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'none',
        px: '24px',
        py: '13px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        color: colorsConfig.mainBlack,
        transition: '0.25s all ease-in-out',
        boxShadow: 'none',
        '&:hover': {
          background: colorsConfig.mainGreen,
          opacity: '0.8',
          transition: '0.25s all ease-in-out',
        },
        '&.xs-only': {
          py: '10px',
        },
      }}
      {...props}
    >
      <AddRounded
        sx={{
          fill: colorsConfig.mainBlack,
          mr: '11px',
          position: 'relative',
          mt: '-1px',
        }}
      />
      {text ? t(text) : children}
    </Button>
  )
}

export default UIButtonAdd
