import React, { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  InputAdornment,
  Modal,
  Table,
  TextField,
  useMediaQuery,
} from '@mui/material'
import TextH1 from '../../ui/Text/TextH1'
import { colorsConfig } from '../../config/themeConfig'
import iconSearch from '../../assests/img/icons/icon-search.svg'
import ButtonHollow from '../../ui/Buttons/ButtonHollow'
import { AddRounded, Close } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import CarTableHead from '../VirtualGarage/CarTableHead'
import CarTableBody from '../VirtualGarage/CarTableBody'
import { tableHeadCellsFinanceCarModal } from '../../config/tablesConfig'
import { API_ENDPOINTS, CDN_BASE_URL } from '../../config/default'
import { privateRequest } from '../../utils/request'
import { useSelector } from 'react-redux'
import carDefaultPicture from '../../assests/img/car-not-found.png'
import { useTranslation } from 'react-i18next'

const FinanceCarModal = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const carWorkflows = useSelector((state) => state.workflows.carWorkflowStates)
  const auctionWorkflows = useSelector((state) => state.workflows.auctionWorkflowStates)
  const dealWorkflows = useSelector((state) => state.workflows.dealWorkflowStates)

  const navigate = useNavigate()

  const xsOnly = useMediaQuery('(max-width: 600px)')
  const tabletOnly = useMediaQuery('(min-width: 600px) and (max-width: 1200px)')

  const [searchLine, setSearchLine] = useState('')
  const [cars, setCars] = useState([])

  const getCarStatus = (combinedStatus, isArchived) => {
    if (!combinedStatus) {
      return 'Outdated car'
    }
    if (isArchived) {
      return 'Archived'
    }
    if (combinedStatus.includes('car_workflow')) {
      return carWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('car_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('auction_workflow')) {
      return auctionWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('auction_workflow.', ''),
      )[0].value
    }
    if (combinedStatus.includes('deal_workflow')) {
      return dealWorkflows.filter(
        (workflow) => workflow.sysName === combinedStatus.replace('deal_workflow.', ''),
      )[0].value
    }
  }

  const handleSetCarPhoto = (photo) => {
    if (photo && photo.photo && photo.photo.path) {
      return `${CDN_BASE_URL}${photo.photo.path}`
    } else {
      return carDefaultPicture
    }
  }

  const renderFinanceButton = () => {
    return (
      <ButtonHollow
        sx={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        {t('button_finance')}
      </ButtonHollow>
    )
  }

  const getCars = () => {
    let link = API_ENDPOINTS.cars.companyCars
    link = `${link}&combinedStatus[]=car_workflow.ready_for_sale&combinedStatus[]=car_workflow.new`
    link = `${link}&combinedStatus[]=car_workflow.appraisal_on_site&combinedStatus[]=car_workflow.quality_check`
    link = `${link}&combinedStatus[]=car_workflow.moderation_failed&combinedStatus[]=car_workflow.requested_appraisal_compound`
    link = `${link}&combinedStatus[]=car_workflow.awaiting_appraisal_compound&combinedStatus[]=car_workflow.appraisal_compound`
    link = `${link}&combinedStatus[]=auction_workflow.pending&combinedStatus[]=auction_workflow.active`
    link = `${link}&combinedStatus[]=auction_workflow.completed`
    if (searchLine) {
      link = `${link}&or[make.name]=${searchLine}&or[model.name]=${searchLine}&or[referenceNumber]=${searchLine}&or[vin]=${searchLine}&or[id]=${searchLine}`
    }
    privateRequest.get(link).then((response) => {
      setCars(
        response.data['hydra:member'].map((item) => {
          return {
            id: item.id,
            link: `/cars/${item.id}?service=Finance`,
            greenCar: item.engineType === 'electric' || item.engineType === 'hybrid',
            cells: [
              {
                type: 'text',
                value: item.id,
              },
              {
                type: 'image',
                value: handleSetCarPhoto(item.mainForeshortening),
              },
              {
                type: 'text-with-caption',
                value: `${item.make.name} ${item.model.name}`,
                caption: `VIN ${item.vin}`,
              },
              {
                type: 'text',
                value: getCarStatus(item.combinedStatus, item.isArchived),
              },
              {
                type: 'component',
                value: renderFinanceButton(),
              },
            ],
          }
        }),
      )
    })
  }

  useEffect(() => {
    if (carWorkflows.length > 0) {
      getCars()
    }
  }, [searchLine, carWorkflows])

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Box
        className={[
          'disable-scrollbar',
          tabletOnly ? 'tablet-only' : '',
          xsOnly ? 'xs-only' : '',
        ].join(' ')}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1029,
          background: 'white',
          borderRadius: '10px',
          padding: '30px',
          maxHeight: '80vh',
          overflow: 'hidden',
          overflowY: 'auto',
          '&.xs-only': {
            width: '90vw',
            maxHeight: '90vh',
            p: '16px',
          },
          '&.tablet-only': {
            width: '90vw',
            maxHeight: '90vh',
            p: '16px',
            pt: '25px',
          },
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: colorsConfig.mainRed,
            }}
          />
        </IconButton>
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&.xs-only': {
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '15px',
            },
            '&.tablet-only': {
              alignItems: 'flex-end',
            },
          }}
        >
          <Box
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              '&.xs-only': {
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '15px',
                width: '100%',
              },
              '&.tablet-only': {
                display: 'block',
              },
            }}
          >
            <TextH1
              className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
              sx={{
                mr: '30px',
                whiteSpace: 'nowrap',
                '&.xs-only': {
                  fontSize: '24px',
                  mr: '20px',
                },
                '&.tablet-only': {
                  fontSize: '32px',
                  mb: '15px',
                },
              }}
            >
              Choose a car
            </TextH1>
            <TextField
              className={xsOnly ? 'xs-only' : ''}
              id="search"
              label="Search"
              size="small"
              value={searchLine}
              onChange={(e) => {
                setSearchLine(e.target.value)
              }}
              sx={{
                position: 'relative',
                mb: '-2px',
                '&.xs-only': {
                  width: '100% !important',
                  '& .MuiInputBase-input': {
                    width: '100% !important',
                  },
                },
                '& .MuiInputBase-input': {
                  width: '205px',
                  height: '38px',
                  boxSizing: 'border-box',
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                  color: colorsConfig.mainBlack,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '6px',
                  borderColor: colorsConfig.mainBlack,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={iconSearch} alt="" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ButtonHollow
            fullWidth={xsOnly}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onClick={() => {
              navigate('/add-new-vehicle')
            }}
          >
            <AddRounded
              sx={{
                fill: colorsConfig.mainBlue,
                mr: '4px',
              }}
            />
            Add new vehicle
          </ButtonHollow>
        </Box>
        <Box
          className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
          sx={{
            mt: '30px',
            '&.xs-only': {
              overflowX: 'auto',
            },
          }}
        >
          <Table
            className={[xsOnly ? 'xs-only' : '', tabletOnly ? 'tablet-only' : ''].join(' ')}
            sx={{
              '&.xs-only': {
                minWidth: '1000px',
              },
            }}
          >
            <CarTableHead small={tabletOnly} tableCells={tableHeadCellsFinanceCarModal} />
            <CarTableBody small={tabletOnly} content={cars} />
          </Table>
        </Box>
      </Box>
    </Modal>
  )
}

export default FinanceCarModal
